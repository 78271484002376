






















































































































import { defineComponent, ref } from "@vue/composition-api";
import { toUserDateTimeFormat } from "@/apps/core/modules/utils/datetime";
import { formatRupiah } from "@/apps/core/modules/utils/text";
import { DialogProgrammatic as Dialog, ModalProgrammatic as Modal, ToastProgrammatic as Toast } from "buefy";
import { isNasabah, isPengelola } from "@/apps/accounts/modules/store";
import { SetoranMandiri } from "../models/setoranMandiri";
import APP_CONFIG from "@/apps/core/modules/config";
import axios from "axios";

export default defineComponent({
  name: "SetoranMandiriListBox",
  props: {
    setoran: { type: SetoranMandiri, required: true },
  },
  components: {
    StatusTag: () => import("@/apps/core/components/StatusTag.vue"),
  },
  setup(props) {
    const imgUrl = require(`@/apps/tabungan/assets/img/transfer.png`);

    const showImage = () => {
      return Modal.open(
        `<p class="image"><img src="${props.setoran.bukti}"></p>`
      );
    };
    const show = ref(true);

    const updateStatus = async () => {
      const updateUrl = `${APP_CONFIG.baseAPIURL}/tabungan/setoran-mandiri/${props.setoran.id}/`;
      const data = { status: props.setoran.status, catatan: props.setoran.catatan };
      try {
        await axios.patch(updateUrl, data);
        Toast.open("Berhasil verifikasi.");
        show.value = false;
        // debugger;
        // listVM.onPageChange(1);
        // listVM.setRefreshRequest('verifikasi-setoran');
      } catch {
        Toast.open("Gagal verifikasi.");
      }
    };

    const confirm = () => {
      if (!props.setoran.status) return;
      Dialog.confirm({
        message: `Anda yakin akan ${props.setoran.status.toLowerCase()} setoran?`,
        confirmText: "Ya",
        cancelText: "Tidak",
        onConfirm: () => updateStatus(),
      });
    };
    return {
      // Data
      detailUrl: "/setoran",

      // Computed
      imgUrl,
      isNasabah,
      isPengelola,
      show,

      // Method
      confirm,
      formatRupiah,
      showImage,
      toUserDateTimeFormat,
    };
  },
});
